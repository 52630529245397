const width = document
  .querySelector("script[src*=capsearch-leadgenerator]")
  .getAttribute("width");
const ident = document
.querySelector("script[src*=capsearch-leadgenerator]")
.getAttribute("ident");
const version = require("../package.json").version;
const loadjs = require("loadjs");

window.renderApplication = function() {
  var node = document.createElement("leadgenerator-widget");
  node.setAttribute("width", width);
  node.setAttribute("ident", ident);
  document.getElementById('leadgenerator').appendChild(node);
};

window.logToCapsearch = function(msg) {
  if (msg) {
  const xmlhttp = new XMLHttpRequest();
  const url = process.env.VUE_APP_CAPSEARCH_URL + "/api/leadgenerator/v1/logger";
  xmlhttp.open("POST", url);
  xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xmlhttp.send(JSON.stringify(msg));
  }
}

try {
  ("use strict");
  (function() {
    var style =
      process.env.VUE_APP_LEADGENERATOR_DIST_URL + "/css/app." + version + ".css";
    var script =
      process.env.VUE_APP_LEADGENERATOR_DIST_URL + "/js/app." + version + ".js";
    loadjs([script, style], "leadgenerator");
    loadjs.ready("leadgenerator", {
      success: function() {
        window.renderApplication();
      },
      async: false,
    });
  })();
} catch(error) {
  window.logToCapsearch({'message': 'Failed starting the loadjs function', 'error': error});
}
